var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tree",attrs:{"id":"divWorkflowTree"}},[_c('BaseScrollbar',{attrs:{"height":"calc(100vh - 225px)"}},[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"q-pa-xs"},[_c('q-tree',{ref:"tree",attrs:{"nodes":_vm.treeNodes,"node-key":"id","no-connectors":"","expanded":_vm.expanded,"selected-color":"white","selected":_vm.selectedNode},on:{"update:expanded":function($event){_vm.expanded=$event},"update:selected":function($event){_vm.selectedNode=$event}},scopedSlots:_vm._u([{key:"default-header",fn:function(prop){return [_c('div',{staticClass:"row items-center"},[(prop.node.icon)?_c('q-icon',{staticClass:"q-mr-sm",attrs:{"name":_vm.selectedNode === prop.node.id
                    ? prop.node.fillIcon
                    : prop.node.icon,"color":prop.node.color,"size":"20px"}}):_vm._e(),_c('div',{class:[
                  _vm.selectedNode === prop.node.id
                    ? 'text-weight-bold'
                    : 'text-weight-normal',
                ]},[_vm._v(" "+_vm._s(prop.node.label)+" ")])],1)]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }