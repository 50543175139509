<template>
  <div class="progress-bar-container" :style="containerStyle">
    <div
      v-for="(item, index) in progressBars"
      :key="index"
      class="progress-bar"
      :style="{
        width: item.width,
        marginTop: index === 0 ? '10px' : '5px',
        marginLeft: '10px',
      }"
    ></div>
    <!-- <div class="progress-bar" style="width:30%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:50%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:20%;margin-top:10px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:40%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:60%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:30%;margin-top:10px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:60%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:70%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:10%;margin-top:10px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:50%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:70%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:20%;margin-top:10px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:40%;margin-top:5px;padding-top:0px;margin-left:10px;"></div>
    <div class="progress-bar" style="width:60%;margin-top:5px;padding-top:0px;margin-left:10px;"></div> -->
  </div>
</template>

<script>
export default {
  props: {
    containerHeight: {
      type: String,
      default: "",
    },
    containerColor: {
      type: String,
      default: "",
    },
    progressBars: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    containerStyle() {
      return {
        height: this.containerHeight,
        backgroundColor: this.containerColor,
      };
    },
  },
};
</script>

<style scoped>
.progress-bar-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.progress-bar {
  border-radius: 30px;
  height: 5%;
  background: #e0e0dee5;
  transform-origin: left center;
  animation: zoomInOut 6s infinite;
}

@keyframes zoomInOut {
  0% {
    transform: scaley(1);
    transform: scaleX(1);
  }
  50% {
    transform: scaley(1.1);
    transform: scaleX(1.1);
  }
  100% {
    transform: scaley(1);
    transform: scaleX(1);
  }
}
</style>
