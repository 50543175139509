<template>
  <div id="search-items">
    <BaseActionButton
      v-tooltip.bottom="'Search By'"
      is-flat
      icon="mdi-filter-plus-outline"
      :color="activeFilter ? 'secondary' : 'gray'"
      no-border
    >
      <q-menu
        auto-close
        transition-show="scale"
        transition-hide="scale"
        anchor="bottom end"
        self="top left"
      >
        <div class="title-3 q-pa-xs border">
          <input
            ref="searchQuery"
            v-model="searchColumns"
            type="text"
            class="q-pa-md"
            placeholder="Search"
            @click.stop=""
            @keyup="searchColumnList(searchColumns)"
          />
        </div>
        <BaseScrollbar :height="scrollbarHeight" width="240px" class="q-pb-sm">
          <template v-for="column in columnList">
            <ListItem
              v-if="
                column.display && (column.isFilter === false ? false : true)
              "
              :key="column.id"
              :icon="
                column.selected ? 'eva-radio-button-on' : 'eva-radio-button-off'
              "
              :is-selected="column.selected"
              :label="column.label"
              @click="updateFilterBy(column.id)"
            />
          </template>
        </BaseScrollbar>
        <!-- ... -->
      </q-menu>
    </BaseActionButton>
  </div>
</template>

<script>
import ListItem from "@/components/common/ListItem.vue";

export default {
  name: "SearchItems",
  components: { ListItem },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    selectedFilterColumn: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeFilter: false,
      searchColumns: "",
      columnList: [],
    };
  },
  computed: {
    scrollbarHeight() {
      const height = this.columns.length * 36;
      return height > 216 ? "276px" : `${height + 58}px`;
    },
  },

  watch: {
    columns: {
      deep: true,
      immediate: true,
      handler() {
        if (this.columns.length && !this.activeFilter) {
          this.columnList = [];
          this.columns.forEach((column) => {
            this.columnList.push({
              ...column,
              selected: false,
              display: "show",
            });
          });
        }
      },
    },

    selectedFilterColumn: {
      deep: true,
      immediate: true,
      handler() {
        if (this.selectedFilterColumn) {
          this.activeFilter = true;
        } else {
          this.activeFilter = false;
        }
      },
    },
  },

  methods: {
    searchColumnList(value) {
      this.columnList.forEach((column) => {
        column.display = "show";
        if (!column.label.toLowerCase().includes(value.toLowerCase())) {
          column.display = "";
        }
      });
    },

    updateFilterBy(id) {
      this.columnList.forEach((column) => {
        column.selected = false;
      });
      let selectedColumn = this.columnList.find((column) => column.id === id);
      if (selectedColumn) {
        selectedColumn.selected = true;
        this.$emit("update:selectedFilterColumn", selectedColumn.label);
        let filter = [];
        let condition = "CONTAINS";
        if (selectedColumn.dataType === "DATE") {
          condition = "IS_EQUALS_TO";
        }
        filter = [
          {
            filters: [
              {
                criteria: selectedColumn.id,
                condition: condition,
                value: "",
                dataType: selectedColumn.dataType,
              },
            ],
            groupCondition: "",
          },
        ];
        this.$emit("update:filterSearchColumn", filter);
        if (filter.length === 0) {
          this.$emit("update");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#search-items {
  .selected {
    color: #00bcd4 !important;
  }

  .notselect {
    color: #64748b !important;
  }

  #action-btn {
    width: 28px !important;
    height: 28px !important;
  }
}
.q-menu .border {
  border: 1px solid var(--divider-color) !important;
}
</style>
