<template>
  <div id="workflowInbox" class="wrapper">
    <div class="row q-col-gutter-sm">
      <div class="col-2">
        <WorkflowTree
          :workflow-list="workflowList"
          :my-inbox-count="myInboxCount"
          :refresh="refreshTree"
          :load-initial.sync="loadInitial"
          @selectedNode="getSelectedNode"
        ></WorkflowTree>
      </div>
      <div :class="activeView === 'GRID' ? 'col-4' : 'col'">
        <InboxList
          :selected-node="selectedNode"
          :my-inbox="myInboxShow"
          :workflow-list="workflowList"
          :workflow.sync="selectedWorkflow"
          :workflow-request-list.sync="workflowRequestList"
          :group-key="groupKey"
          :wform="form"
          :repository="repository"
          :process-actions="processActions"
          :process-actions-add-on="processActionsAddOn"
          :selected-process.sync="selectedProcess"
          :full-view.sync="fullView"
          :active-view.sync="activeView"
          :internal-forward="internalForward"
          :user-list="
            internalForward.users.length ? internalForward.users : userList
          "
          :group-list="
            internalForward.groups.length ? internalForward.groups : groupList
          "
          :current-page.sync="currentPage"
          :items-per-page.sync="itemsPerPage"
          :total-items.sync="totalItems"
          :master-form-id.sync="masterFormId"
          :data-imported="dataImported"
          :user-signature="userSignature"
          :task-form-id="taskFormId"
          :task-entry-id="taskEntryId"
          :task-entries.sync="taskEntries"
          :check-list.sync="checkList"
          :custom-reply.sync="customReply"
          :user-reply.sync="userReply"
          :to-requester.sync="toRequester"
          :data-validation-values.sync="dataValidationValues"
          :custom-close-process-sheet="customCloseProcessSheet"
          :selected-completed-node="selectedCompletedNode"
          :hide-columns.sync="hideColumns"
          :add-task-enabled="addTaskEnabled"
          :loading-bar-inbox="loadingBarInbox"
          :portal-action="portalAction"
          :listed-data="listedData"
          :dashboard="dashboard"
          @show="showRequestPreview"
          @refresh="refresh"
          @history="showHistory"
          @subHistory="showSubHistory"
          @filter="updateFilter"
          @sortBy="updateSortBy"
          @upload="$refs.input.click()"
          @close="dataImported = false"
          @addTask="addTask"
          @viewTask="viewTask"
          @validation="dataValidation"
          @paymentTable="showPaymentTable"
          @release="releaseReservedRequest"
          @showFormEntryHistory="showFormEntryHistoryModal = true"
          @issavesheet="save"
          @isDelete="deleteItem"
        ></InboxList>
        <input
          ref="input"
          type="file"
          accept=".csv,.xls,.xlsx"
          class="hidden"
          @change="uploadFile"
        />
      </div>
      <div v-if="activeView === 'GRID'" class="col">
        <div class="card q-pa-xs">
          <FormsCard
            v-if="selectedRequestCount"
            :workflow="selectedWorkflow"
            :form="form"
            :selected-process="selectedProcess"
            :process-actions="processActions"
            :task-entries="taskEntries"
            :check-list.sync="checkList"
            :jira-accounts="jiraAccounts"
            :selected-node="selectedNode"
            :hide-columns.sync="hideColumns"
            :portal-action="portalAction"
            @proceed="requestAction"
            @expand="fullView = true"
            @history="showHistory"
            @addTask="addTask"
            @viewTask="viewTask"
            @validation="dataValidation"
            @paymentTable="showPaymentTable"
          ></FormsCard>
        </div>
      </div>
    </div>

    <Modal
      v-model="processForwardModal"
      width="40vw"
      height="50vh"
      has-footer
      @input="
        {
          saveAction = '';
          processForwardModal = false;
        }
      "
    >
      <!-- title -->

      <template #title
        >"{{ selectedProcess.requestNo }}"
        <template v-if="isTenantGOTO() || isTenantArmgroup()">
          Process Assign
        </template>
        <template v-else> Process Forward </template>
      </template>

      <!-- ... -->

      <template #default>
        <div id="processForward">
          <!-- user -->

          <MultiSelectField
            v-if="internalForward.proceed"
            v-model="forwardUsers"
            label="users"
            is-mandatory
            :options="
              internalForward.users.length ? internalForward.users : userList
            "
            class="field"
          />

          <!-- ... -->

          <!-- group -->

          <MultiSelectField
            v-if="internalForward.groups.length"
            v-model="forwardGroups"
            label="group"
            :options="
              internalForward.groups.length ? internalForward.groups : groupList
            "
            class="field"
          />

          <!-- ... -->
        </div>
      </template>

      <template #footer>
        <template v-if="isTenantGOTO() || isTenantArmgroup()"
          ><BaseButton
            label="Assign"
            color="orange"
            @click="
              {
                processForwardModal = false;
                requestAction('Assign', false, false);
              }
            "
          />
        </template>
        <template v-else>
          <BaseButton
            label="Forward"
            color="orange"
            @click="
              {
                processForwardModal = false;
                requestAction('Forward', false, false);
              }
            "
          />
        </template>
      </template>
    </Modal>

    <Modal
      v-model="dataValidationModal"
      width="70vw"
      @input="dataValidationModal = false"
    >
      <!-- title -->

      <template #title> Data Verification</template>

      <!-- ... -->

      <template #default>
        <div id="dataValidation">
          <table>
            <thead>
              <tr>
                <!-- columns -->

                <th v-for="column in dataValidationColumns" :key="column.id">
                  {{ column.label }}
                </th>

                <!-- ... -->
              </tr>
            </thead>

            <tbody>
              <tr v-for="item in dataValidationValues" :key="item.id">
                <!-- columns -->

                <td v-for="column in dataValidationColumns" :key="column.id">
                  <slot
                    :name="column.name"
                    :value="item[column.name]"
                    :item="item"
                  >
                    <template v-if="column.type === 'STATUS'">
                      <template v-if="item[column.name] === 'Matched'">
                        <span class="text-green"> {{ item[column.name] }}</span>
                      </template>
                      <template v-else-if="item[column.name] === 'Verified'">
                        <span class="text-primary">
                          {{ item[column.name] }}</span
                        >
                      </template>
                      <template v-else-if="item[column.name] === 'Not Matched'">
                        <span class="text-red"> {{ item[column.name] }}</span>
                      </template>
                      <template v-else>
                        <span class="text-orange">
                          {{ item[column.name] || "-" }}</span
                        >
                      </template>
                    </template>
                    <template v-else-if="column.name === 'ocrValue'">
                      <template v-if="isJsonString(item[column.name])">
                        <div
                          v-for="(ocr, index) in JSON.parse(item[column.name])"
                          :key="index"
                          class="row items-center"
                        >
                          {{ ocr.name }} : {{ ocr.value || "-" }}
                        </div>
                      </template>
                      <template v-else>
                        {{ item[column.name] || "-" }}
                      </template>
                    </template>
                    <template v-else>
                      {{ item[column.name] || "-" }}
                    </template>
                  </slot>
                </td>

                <!-- ... -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>

    <Modal
      v-model="paymentTableModal"
      width="70vw"
      :has-footer="selectedNode === 'inbox' || selectedNode === 'payment'"
      @input="paymentTableModal = false"
    >
      <!-- title -->

      <template #title> Payment Table</template>

      <!-- ... -->

      <template #action>
        <BaseActionButton
          v-tooltip.top="'Export'"
          icon="mdi-table-arrow-right"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="export_Data"
        />
      </template>

      <template #default>
        <div id="paymentTable">
          <template
            v-if="
              selectedNode === 'inbox' &&
              (selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv' ||
                !isPaymentFound)
            "
          >
            <ValidationObserver ref="form">
              <div class="row">
                <div class="col-3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Total Payment"
                    :rules="{ required: true, min_value: 1 }"
                    class="col"
                  >
                    <NumberField
                      v-model="totalPayment"
                      label="Total Payment"
                      is-mandatory
                      class="q-pa-sm"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Lumpsum Amount"
                    :rules="{ required: true, min_value: 1, max_value: 2000 }"
                    class="col"
                  >
                    <NumberField
                      v-model="lumpsumAmount"
                      label="Lumpsum Amount"
                      is-mandatory
                      class="q-pa-sm"
                      :max="2000"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-3">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Benefit Type"
                    :rules="{ required: true }"
                    class="col"
                  >
                    <SelectField
                      v-model="benefitType"
                      label="Benefit Type"
                      is-mandatory
                      :options="benefitTypes"
                      class="q-pa-sm"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </div>
                <div class="col-3">
                  <BaseButton
                    label="Calculate"
                    class="calculateBtn col-auto q-ml-md"
                    @click="paymentCalculation"
                  />
                </div>
              </div>
            </ValidationObserver>
            <div
              class="row col"
              style="display: flex; justify-content: flex-end"
            >
              <FormFieldLabel
                label="Number of payments to approve"
                class="q-mr-sm q-mt-xs"
              />
              <NumberField
                v-model="numberOfApprovePayment"
                class="q-pa-sm"
                :min="0"
                :max="paymentTableValues.length"
                :is-clearable="false"
                :is-readonly="!paymentTableValues.length"
              />
              <BaseButton
                label="Payment Approve"
                color="secondary"
                class="q-ml-sm"
                style="margin-top: 12px"
                :disabled="!paymentTableValues.length"
                @click="paymentApprove"
              />
              <BaseButton
                label="Clear"
                color="red"
                class="q-ml-sm"
                style="margin-top: 12px"
                :disabled="!paymentTableValues.length"
                @click="clearPaymentApprove"
              />
            </div>
          </template>
          <div class="col q-pa-sm">
            <BaseScrollbar
              :height="
                selectedNode === 'inbox' &&
                (selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv' ||
                  !isPaymentFound)
                  ? 'calc(100vh - 510px)'
                  : ''
              "
            >
              <table class="q-mt-sm">
                <thead>
                  <tr>
                    <!-- columns -->

                    <th v-for="column in paymentTableColumns" :key="column.id">
                      {{ column.label }}
                    </th>

                    <!-- ... -->

                    <th
                      v-if="
                        selectedProcess.activityId ===
                          '90xxPehjko0dNYRjbX5wm' && isPaymentFound
                      "
                      class="actions"
                    >
                      <BaseActionButton
                        v-tooltip:top="'add row'"
                        color="primary"
                        icon="eva-plus"
                        no-border
                        @click="addPaymentRow"
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in paymentTableValues"
                    :key="'paymentrow' + index"
                  >
                    <!-- columns -->

                    <td v-for="column in paymentTableColumns" :key="column.id">
                      <template
                        v-if="
                          selectedProcess.activityId ===
                            '90xxPehjko0dNYRjbX5wm' &&
                          isPaymentFound &&
                          !item['delete']
                        "
                      >
                        <template v-if="column.name === 'approval'">
                          <template v-if="item[column.name] === 'Approved'">
                            <span
                              :name="column.name"
                              :value="item[column.name]"
                              :item="item"
                              class="text-green"
                            >
                              {{ item[column.name] }}
                            </span>
                          </template>
                          <template v-else>
                            <span
                              :name="column.name"
                              :value="item[column.name]"
                              :item="item"
                              class="text-red"
                            >
                              {{ item[column.name] }}
                            </span>
                          </template>
                        </template>
                        <template v-else-if="column.name === 'paymentDate'">
                          <DateField v-model="item[column.name]" />
                        </template>
                        <template v-else>
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            {{ item[column.name] }}
                          </slot>
                        </template>
                      </template>
                      <template v-else>
                        <template v-if="column.type === 'SINGLE_SELECT'">
                          <SelectField
                            v-model="item[column.name]"
                            :options="approvalTypes"
                            @input="updateApprovalDate(index)"
                          />
                        </template>
                        <template v-else-if="column.type === 'DATE'">
                          <DateField v-model="item[column.name]" />
                        </template>
                        <template
                          v-else-if="
                            column.name === 'baseAmount' && item['delete']
                          "
                        >
                          <NumberField
                            v-model="item[column.name]"
                            placeholder="0.00"
                            @input="calcOtherColumns(index)"
                          />
                        </template>
                        <template v-else>
                          <slot
                            :name="column.name"
                            :value="item[column.name]"
                            :item="item"
                          >
                            {{ item[column.name] }}
                          </slot>
                        </template>
                      </template>
                    </td>

                    <!-- ... -->

                    <td
                      v-if="
                        selectedProcess.activityId ===
                          '90xxPehjko0dNYRjbX5wm' && isPaymentFound
                      "
                      class="actions"
                    >
                      <BaseActionButton
                        v-if="item['delete']"
                        v-tooltip="'delete row'"
                        is-flat
                        color="red"
                        icon="eva-trash-outline"
                        no-border
                        @click="removePaymentRow(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </BaseScrollbar>
          </div>
        </div>
      </template>

      <template
        v-if="selectedNode === 'inbox' || selectedNode === 'payment'"
        #footer
      >
        <template v-if="selectedProcess.activityId === 'psyCrYXtKfcs-EJmrYIhv'">
          <BaseButton
            label="Recommend to Approve"
            color="green"
            class="q-mr-sm"
            @click="
              {
                paymentTableModal = false;
                customCloseProcessSheet = !customCloseProcessSheet;
                updatePaymentTable('Recommend to Approve');
              }
            "
          />
          <BaseButton
            label="Recommend to Reject"
            color="red"
            @click="
              {
                paymentTableModal = false;
                customCloseProcessSheet = !customCloseProcessSheet;
                updatePaymentTable('Recommend to Reject');
              }
            "
          />
        </template>
        <template v-else>
          <BaseButton
            label="Update Payments"
            color="primary"
            @click="
              {
                paymentTableModal = false;
                updatePaymentTable();
              }
            "
          />
        </template>
      </template>
    </Modal>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <SingleChoiceField
          v-if="checkTenant()"
          v-model="commentAction"
          :options="commentActions"
          :options-per-line="2"
          class="actions q-mr-sm noborder"
        />
        <BaseButton
          label="Post Comments"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <Confirm
      v-model="actionPasswordConfirm"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="actionPasswordConfirm = false"
    >
      <template #title> Action Password</template>

      <template #default>
        <ValidationObserver ref="passwordForm">
          <!-- password -->

          <ValidationProvider
            v-slot="{ errors }"
            name="password"
            :rules="{ required: true, password: true }"
          >
            <PasswordField
              v-model="actionUserPassword"
              is-mandatory
              :is-readonly="isLoading"
              :error="errors[0]"
              :auto-focus="true"
              class="q-my-lg"
              @enter="validatePassword"
            />
          </ValidationProvider>

          <!-- ... -->
        </ValidationObserver>
      </template>

      <template #footer>
        <BaseButton
          label="validate"
          :is-loading="isLoading"
          @click="validatePassword"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="actionConfirmAlert"
      icon="eva-alert-circle-outline"
      icon-color="red"
      @input="
        {
          saveAction = '';
          workflowFormModel = {};
          actionConfirmAlert = false;
        }
      "
    >
      <template #title> Confirmation</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Are you sure you want to confirm the action as "<b>{{
              actionText
            }}</b
            >"?
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="
            {
              saveAction = '';
              workflowFormModel = {};
              actionConfirmAlert = false;
            }
          "
        />
        <BaseButton
          label="yes"
          color="primary"
          @click="
            {
              actionConfirmAlert = false;
              requestAction(actionText, false, false);
            }
          "
        />
      </template>
    </Confirm>

    <AddTask
      v-if="taskFormId"
      :value="addTaskSheet"
      :form-settings="addTaskForm.settings"
      :panels="addTaskForm.panels"
      :secondary-panels="addTaskForm.secondaryPanels"
      :form-id="taskFormId"
      :repository-id="taskRepositoryId"
      :task-model="selectedTask"
      :workflow-task="true"
      :mode="taskMode"
      @close="addTaskSheet = false"
      @addEntry="addTaskEntry"
    ></AddTask>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />

    <Confirm
      v-model="requestLockedModal"
      icon="eva-info-outline"
      icon-color="secondary"
      :has-footer="false"
      @input="requestLockedModal = false"
    >
      <template #title> Progress: Action unavailable</template>

      <template #default>
        <div class="q-py-md">
          <p>
            The request {{ selectedProcess.requestNo }} is currently being
            processed by "<b>{{ selectedProcess.ticketLockedByName }}</b
            >", preventing any further actions on it.
          </p>
        </div>
      </template>
      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="requestLockedModal = false"
        />
        <BaseButton
          label="View"
          color="primary"
          @click="updateRequestReserved"
        />
      </template>
    </Confirm>

    <Confirm
      v-model="requestForLockModal"
      icon="eva-alert-circle-outline"
      icon-color="orange"
      @input="requestForLockModal = false"
    >
      <template #title> Reserved for your action</template>

      <template #default>
        <div class="q-py-md">
          <p>
            Would you like to approve the request "<b>{{
              selectedProcess.requestNo
            }}</b
            >, it will be assigned to you, others cannot do any action on this
            request, once you select proceed.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          color="primary"
          @click="requestForLockModal = false"
        />
        <BaseButton
          label="proceed"
          color="primary"
          @click="updateRequestReserved"
        />
      </template>
    </Confirm>

    <FormEntryHistory
      v-if="showFormEntryHistoryModal"
      :value.sync="showFormEntryHistoryModal"
      :workflow-id="selectedWorkflow.id"
      :process-id="selectedProcess.processId"
    />

    <UnpinDashboard
      v-model="isDeleteConfirmationVisible"
      :delete-data="deleteData"
      :label="selectedWorkflow.name + ' ' + selectedNode"
      @delete="
        {
          dashboard = false;
          getFilterList();
        }
      "
    />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import WorkflowTree from "@/views/workflows/views/workflows-inbox/components/WorkflowTree";
import InboxList from "@/views/workflows/views/workflows-inbox/components/InboxList";
import FormsCard from "@/views/workflows/views/workflows-inbox/components/FormsCard";
import Modal from "@/components/common/popup/Modal.vue";
import Confirm from "@/components/common/popup/Confirm.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import {
  form,
  workflow,
  user,
  group,
  integration,
  auth,
  repository,
  custom,
  menu,
  portal,
  dashboard,
} from "@/api/factory.js";
import axios from "axios";
import AddTask from "@/views/tasks/views/tasks-browse-and-trash/components/AddTask";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import { exportData } from "@/helpers/export-excel-data";
import { mapState } from "vuex";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";
import FormEntryHistory from "@/components/common/workflow/FormEntryHistory.vue";
import UnpinDashboard from "@/components/common/display/item-actions/components/UnpinDashboard.vue";

export default {
  name: "WorkflowInbox",

  components: {
    ValidationObserver,
    ValidationProvider,
    WorkflowTree,
    InboxList,
    FormsCard,
    Modal,
    Confirm,
    MultiSelectField,
    AddTask,
    SelectField,
    NumberField,
    TextBuilderField,
    SingleChoiceField,
    PasswordField,
    DateField,
    FormFieldLabel,
    ProcessHistory,
    FormEntryHistory,
    UnpinDashboard,
  },

  data() {
    return {
      selectedNode: "",
      selectedCompletedNode: "",
      myInboxCount: 0,
      myInboxShow: false,
      workflowList: [],
      selectedWorkflow: {},
      workflowRequestList: [],
      selectedProcess: {},
      form: {},
      repository: {},
      processActions: [],
      processActionsAddOn: [],
      fullView: false,
      activeView: "GRID",
      groupKey: [],

      processForwardModal: false,
      internalForward: {
        proceed: false,
        users: [],
        groups: [],
      },
      userList: [],
      groupList: [],
      forwardUsers: [],
      forwardGroups: [],
      filterBy: [],
      sortBy: { criteria: "", order: "DESC" },
      historyTitle: "",
      totalItems: 0,
      itemsPerPage: 50,
      currentPage: 1,
      masterFormId: 0,
      dataImported: false,
      userSignature: false,
      taskFormId: 0,
      taskRepositoryId: 0,
      addTaskForm: {},
      addTaskSheet: false,
      taskEntryId: [],
      taskEntries: [],
      taskEntryList: [],
      taskMode: "",
      selectedTask: {},
      checkList: [],
      dataValidationValues: [],
      dataValidationModal: false,
      dataValidationColumns: [
        {
          id: this.$nano.id(),
          name: "fieldName",
          label: "FIELD",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "formValue",
          label: "FORM DATA",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "ocrValue",
          label: "PDF DATA",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "status",
          label: "STATUS",
          type: "STATUS",
        },
      ],
      paymentTableModal: false,
      totalPayment: 0,
      lumpsumAmount: 0,
      benefitType: "",
      benefitTypes: [
        {
          id: this.$nano.id(),
          label: "Monthly",
          value: "Monthly",
        },
        {
          id: this.$nano.id(),
          label: "Bi-weekly",
          value: "BiWeekly",
        },
        {
          id: this.$nano.id(),
          label: "Semi-monthly",
          value: "SemiMonthly",
        },
        {
          id: this.$nano.id(),
          label: "Weekly",
          value: "Weekly",
        },
      ],
      numberOfApprovePayment: 0,
      paymentTableColumns: [
        {
          id: this.$nano.id(),
          name: "paymentNumber",
          label: "Payment Number",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "baseAmount",
          label: "Payment Base Amount",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "claimAmount",
          label: "Claim Amount (20% Increased)",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "cumulativeTotal",
          label: "Cumulative Total",
          type: "SHORT_TEXT",
        },
        {
          id: this.$nano.id(),
          name: "approval",
          label: "Approval",
          type: "SINGLE_SELECT",
        },
        {
          id: this.$nano.id(),
          name: "approvedDate",
          label: "Approved Date",
          type: "DATE",
        },
        {
          id: this.$nano.id(),
          name: "paymentDate",
          label: "Payment Date",
          type: "DATE",
        },
      ],
      paymentTableValues: [],
      isPaymentFound: false,
      jiraAccounts: [],
      advancedCommentsSheet: false,
      commentActions: [
        {
          id: this.$nano.id(),
          label: "Internal",
          value: 2,
        },
        {
          id: this.$nano.id(),
          label: "Public",
          value: 1,
        },
      ],
      commentAction: 1,
      advancedCommentText: "",
      actionText: "",
      actionRemarks: false,
      actionPassword: false,
      actionPasswordConfirm: false,
      actionUserPassword: "",
      isLoading: false,
      refreshTree: true,
      loadInitial: true,
      actionConfirm: false,
      actionConfirmAlert: false,
      customReply: false,
      userReply: false,
      toRequester: false,
      customCloseProcessSheet: false,
      approvalTypes: [
        {
          id: this.$nano.id(),
          label: "Approve",
          value: "Approved",
        },
        {
          id: this.$nano.id(),
          label: "Cancel",
          value: "Cancelled",
        },
      ],
      activeViewLoad: null,
      hideColumns: [],
      addTaskEnabled: false,
      loadingBarInbox: false,
      historyProcessId: 0,
      requestLockedModal: false,
      requestForLockModal: false,
      showFormEntryHistoryModal: false,
      portalAction: false,
      issavesheet: false,
      nodeType: "",
      refreshCount: false,
      listedData: [],
      uId: 0,
      jsonFilters: [],
      node: "",
      dashboard: false,
      Jsonfiltering: "",
      filtersArray: "",
      isDeleteConfirmationVisible: false,
      deleteData: {},
      success: false,
    };
  },

  computed: {
    ...mapState(["loadingBarPage", "loadingBar"]),

    selectedRequestCount() {
      let count = this.workflowRequestList.filter(
        (process) => process.checked === true
      ).length;
      return count < 2;
    },
  },

  watch: {
    success: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.success) {
          await this.getFilterList();
          this.refresh();
        }
      },
    },
  },

  created() {
    this.getAppConnection();
    if (this.isTestServer()) {
      this.getUserWorkflowTest();
    } else {
      if (!this.isTenantArmgroup()) {
        this.getUserMyInbox();
      }
      this.getUserWorkflow();
    }
    this.getUsers();
    this.getGroups();
    this.getTaskForm();
    if (this.isTenantSobha() || this.isTenantArmgroup()) {
      this.activeViewLoad = setInterval(() => {
        this.activeView = "LIST";
        clearInterval(this.activeViewLoad);
      }, 1000);
    }
    this.getFilterList();
  },

  methods: {
    checkTenant() {
      if (this.isTenantArmgroup()) {
        this.commentAction = 2;
        return false;
      }
      return true;
    },

    isTenantGOTO() {
      let origin = location.origin;
      if (
        (origin === "https://app.ezofis.com1" ||
          origin === "https://appuat.ezofis.com") &&
        this.$store.state.session.tenantId === 2
      ) {
        return true;
      }
      return false;
    },

    isTenantArmgroup() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://trial.ezofis.com" &&
          this.$store.state.session.tenantId === 12) ||
        origin === "http://localhost:80801"
      ) {
        return true;
      }
      return false;
    },

    isTenantSobha() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return true;
      }
      return false;
    },

    isTestServer() {
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc05.azurewebsites.net" ||
        (origin === "https://appuat.ezofis.com" &&
          this.$store.state.session.tenantId === 3) ||
        origin === "http://52.172.32.881" ||
        origin === "https://test.ezofis.com" ||
        origin === "http://localhost:80801"
      ) {
        return true;
      }
      return false;
    },

    addPaymentRow() {
      const model = {
        paymentNumber: this.paymentTableValues.length + 1,
        baseAmount: "",
        claimAmount: 0,
        cumulativeTotal: 0,
        approval: "",
        approvedDate: "",
        paymentDate: "",
        benefitType: this.benefitType,
        delete: true,
      };

      this.paymentTableValues.push(model);
    },

    removePaymentRow(rowIdx) {
      this.paymentTableValues.splice(rowIdx, 1);
    },

    calcOtherColumns(rowIdx) {
      let total = 0;
      let baseAmount = this.paymentTableValues[rowIdx].baseAmount;
      let claimAmount = baseAmount + baseAmount * 0.2;
      this.paymentTableValues[rowIdx].claimAmount = claimAmount.toFixed(2);
      this.paymentTableValues.forEach((row) => {
        total += Number(row.claimAmount);
      });
      this.paymentTableValues[rowIdx].cumulativeTotal = total.toFixed(2);
    },

    updateFilter(filterBy) {
      this.currentPage = 1;
      this.filterBy = filterBy;
      this.refresh();
    },

    updateSortBy(sortBy) {
      this.currentPage = 1;
      this.sortBy = sortBy;
      this.refresh();
    },

    addTaskEntry(formId, entryId) {
      if (this.selectedProcess.processId) {
        this.insertTaskEntry(formId, entryId);
      } else {
        this.taskEntryId.push({
          formId: formId,
          formEntryId: entryId,
        });
        this.getTaskFormEntry(formId, entryId);
      }
    },

    addTask() {
      this.selectedTask = {};
      this.taskMode = "NEW";
      this.addTaskSheet = true;
    },

    viewTask(id) {
      let filterTask = this.taskList.filter((row) => row.formEntryId === id);
      if (filterTask.length) {
        this.selectedTask = filterTask[0].entryInfo[0];
        this.selectedTask = {
          id: filterTask[0].formEntryId,
          ...filterTask[0].entryInfo[0],
        };
      }
      this.taskMode = "EDIT";
      this.addTaskSheet = true;
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    refresh(refreshCount = true) {
      if (this.currentPage === 1) {
        this.workflowRequestList = [];
        this.groupBy = [];
        this.totalItems = 0;
        this.selectedProcess = {};
      }
      if (this.myInboxShow) {
        this.getUserMyInbox();
        this.getMyInboxData(refreshCount);
      } else {
        this.getWorkflowData(refreshCount);
      }
    },

    async getSelectedNode(node, refreshCount) {
      this.nodeType = node;
      this.refreshCount = refreshCount;
      this.workflowRequestList = [];
      this.groupBy = [];
      this.sortBy = { criteria: "", order: "DESC" };
      this.currentPage = 1;
      this.totalItems = 0;
      this.selectedProcess = {};
      this.filterBy = [];
      const type = node.split("-");
      this.selectedNode = type[0];
      this.selectedCompletedNode = "";
      this.portalAction = false;
      if (this.myInboxShow) {
        this.selectedWorkflow = {};
      }
      this.myInboxShow = false;
      if (type.length > 1) {
        if (this.selectedWorkflow.id != type[1]) {
          this.selectedWorkflow = this.workflowList.find(
            (workflow) => workflow.id == type[1]
          );
          this.getPortal();
          this.form = {};
          this.repository = {};
          if (this.selectedWorkflow.wFormId) {
            await this.getForm();
          } else if (this.selectedWorkflow.repositoryId) {
            await this.getRepository();
          }
          this.getHideColumns();
        }
        if (type.length === 3) {
          this.selectedCompletedNode = type[2];
        }
        this.getWorkflowData(refreshCount);
      } else {
        this.myInboxShow = true;
        this.getMyInboxData(refreshCount);
      }
    },

    getWorkflowData(refreshCount) {
      this.dashboard = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      List.forEach((item) => {
        const types = item.filterJson[0].node.split("-");
        let node = types[0];
        let Jsonfiltering = "";
        let filtersArray = "";
        let filterBy = this.filterBy;
        if (filterBy.length) {
          for (let i = 0; i < filterBy.length; i++) {
            let item = filterBy[i];
            if ("id" in item) {
              delete item["id"];
            }
            if (Array.isArray(item.filters)) {
              for (let j = 0; j < item.filters.length; j++) {
                let filter = item.filters[j];
                if (typeof filter.value === "string") {
                  filter.value = filter.value.toLowerCase();
                }
                if ("id" in filter) {
                  delete filter["id"];
                }
              }
            }
          }
          Jsonfiltering = JSON.stringify(filterBy, null, 2);
        } else if (!filterBy.length) {
          Jsonfiltering = JSON.stringify(filterBy);
        }
        let parsedData = JSON.parse(item.filterJson[0].filters);
        if (parsedData.length) {
          for (let i = 0; i < parsedData.length; i++) {
            let item = parsedData[i];
            if ("id" in item) {
              delete item["id"];
            }
            if (Array.isArray(item.filters)) {
              for (let j = 0; j < item.filters.length; j++) {
                let filter = item.filters[j];
                if (typeof filter.value === "string") {
                  filter.value = filter.value.toLowerCase();
                }
                if ("id" in filter) {
                  delete filter["id"];
                }
              }
            }
          }
          filtersArray = JSON.stringify(parsedData, null, 2);
        } else if (!parsedData.length) {
          filtersArray = item.filterJson[0].filters;
        }
        if (
          item.filterJson[0].uId === this.selectedWorkflow.id &&
          node === this.selectedNode &&
          filtersArray === Jsonfiltering
        ) {
          this.dashboard = true;
        }
      });
      if (this.selectedNode === "inbox") {
        if (this.isTestServer()) {
          this.getInboxListTest();
        } else {
          this.getInboxList();
        }
      } else if (this.selectedNode === "sent") {
        if (this.isTestServer()) {
          this.getSentListTest();
        } else {
          this.getSentList();
        }
      } else if (this.selectedNode === "completed") {
        if (this.isTestServer()) {
          this.getCompletedListTest();
        } else {
          this.getCompletedList();
        }
      } else if (this.selectedNode === "payment") {
        this.getPaymentList();
      } else if (this.selectedNode === "common") {
        this.getCommonInbox();
      } else {
        this.groupKey = [];
        this.totalItems = 0;
      }
      if (refreshCount) {
        this.refreshProcessCount();
      }
    },

    showHistory(processId) {
      this.selectedProcess = this.workflowRequestList.find(
        (row) => row.processId === processId
      );
      this.historyProcessId = processId;
    },

    showSubHistory(subProcessId, mainWfProcessId) {
      const subWorkflow = this.workflowRequestList.find(
        (row) => row.processId === mainWfProcessId
      );
      if (subWorkflow) {
        this.selectedProcess = subWorkflow.subworkflowTransaction.find(
          (subRow) => subRow.processId === subProcessId
        );
        if (this.selectedProcess) {
          this.selectedProcess.id = this.selectedProcess.subWorkFlowId;
          this.historyProcessId = subProcessId;
        }
      }
    },

    async getMyInboxData(refreshCount) {
      if (this.selectedNode === "inbox") {
        if (this.isTenantGOTO()) {
          this.selectedWorkflow = this.workflowList.find(
            (workflow) => workflow.id == 1
          );
          if (this.selectedWorkflow.wFormId) {
            await this.getForm();
          }
          this.getMyInboxListGOTO();
        } else {
          this.getMyInboxList();
        }
      } else {
        this.groupKey = [];
      }
      if (refreshCount) {
        this.refreshProcessCount();
      }
    },

    async refreshProcessCount() {
      if (this.selectedNode) {
        this.refreshTree = false;
      }
      if (this.isTestServer()) {
        const { error, payload } = await workflow.getUserWorkflowTest();

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          this.workflowList.forEach((workflow) => {
            let index = payload.findIndex((item) => item.id === workflow.id);
            if (index > -1) {
              workflow.inboxCount = payload[index].inboxCount;
              workflow.processCount = payload[index].processCount;
              workflow.completedCount = payload[index].completedCount;
              workflow.paymentProcessCount = payload[index].paymentProcessCount;
              workflow.runningCount = payload[index].runningCount;
            }
          });
        }
      } else {
        const { error, payload } = await workflow.getUserWorkflow();

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          this.workflowList.forEach((workflow) => {
            let index = payload.findIndex((item) => item.id === workflow.id);
            if (index > -1) {
              workflow.inboxCount = payload[index].inboxCount;
              workflow.processCount = payload[index].processCount;
              workflow.completedCount = payload[index].completedCount;
              workflow.paymentProcessCount = payload[index].paymentProcessCount;
              workflow.runningCount = payload[index].runningCount;
            }
          });
        }
      }
    },

    async showRequestPreview(processId, workflowId, requestView) {
      this.processActions = [];
      this.processActionsAddOn = [];
      this.internalForward = {
        proceed: false,
        users: [],
        groups: [],
      };
      this.userSignature = false;
      let process = this.workflowRequestList.filter(
        (req) => req.processId === processId && req.id === workflowId
      );
      this.checkList = [];
      if (process.length) {
        if (!this.myInboxShow) {
          this.selectedProcess = process[0];
        }
        if (
          this.selectedNode === "inbox" ||
          this.selectedNode === "common" ||
          this.selectedNode === "payment"
        ) {
          let wflowJson;
          if (this.myInboxShow) {
            this.form = {};
            this.selectedWorkflow = this.workflowList.find(
              (workflow) => workflow.id == process[0].id
            );
            this.getPortal();
            wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
            if (
              this.selectedWorkflow.wFormId &&
              Object.keys(this.form).length === 0
            ) {
              await this.getForm();
            }
            this.getHideColumns();
            this.selectedProcess = process[0];
          } else {
            wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
          }
          this.processActions = [];
          this.processActionsAddOn = [];
          if (
            this.isTenantArmgroup() &&
            this.selectedProcess.activityId === "psyCrYXtKfcs-EJmrYIhv1"
          ) {
            wflowJson.rules.forEach((rule) => {
              if (
                rule.fromBlockId === this.selectedProcess.activityId &&
                rule.proceedAction === "Force Close"
              ) {
                this.processActions.push(rule.proceedAction);
                this.processActionsAddOn.push({
                  action: rule.proceedAction,
                  remarks: rule.remarks || false,
                  passwordAccess: rule.passwordAccess || false,
                  confirm: rule.confirm || false,
                });
              }
            });
          } else {
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === this.selectedProcess.activityId) {
                if (
                  this.isTenantArmgroup() &&
                  this.selectedNode === "common" &&
                  this.selectedProcess.activityId === "szS6B-q1x9acpB83rP6jT"
                ) {
                  if (this.$store.state.session.groups) {
                    let userGroupList = this.$store.state.session.groups;
                    if (userGroupList.length) {
                      let assigner = userGroupList.find(
                        (group) => group.id === 3
                      );
                      if (assigner) {
                        this.processActions.push(rule.proceedAction);
                        this.processActionsAddOn.push({
                          action: rule.proceedAction,
                          remarks: rule.remarks || false,
                          passwordAccess: rule.passwordAccess || false,
                          confirm: rule.confirm || false,
                        });
                      }
                    }
                  }
                } else {
                  if (this.processActions.indexOf(rule.proceedAction) < 0) {
                    this.processActions.push(rule.proceedAction);
                    this.processActionsAddOn.push({
                      action: rule.proceedAction,
                      remarks: rule.remarks || false,
                      passwordAccess: rule.passwordAccess || false,
                      confirm: rule.confirm || false,
                    });
                  }
                }
              }
            });
          }

          wflowJson.blocks.forEach((block) => {
            if (block.id === this.selectedProcess.activityId) {
              //console.log(block.settings);
              if (
                this.selectedProcess.lastAction === "Forward" &&
                block.settings.forwardedUserAction === "REPLY"
              ) {
                this.processActions = [];
                this.processActionsAddOn = [];
                this.processActions.push(
                  "Reply to " + this.selectedProcess.transaction_createdByEmail
                );
                this.processActionsAddOn.push({
                  action:
                    "Reply to " +
                    this.selectedProcess.transaction_createdByEmail,
                  remarks: true,
                  passwordAccess: false,
                  confirm: false,
                });
                this.customReply = true;
              } else {
                if (
                  block.settings.userReply &&
                  this.processActions.indexOf("Reply") === -1
                ) {
                  this.processActions.unshift(
                    "Reply to " + this.selectedProcess.lastActionStageName
                  );
                  this.userReply = true;
                }
                if (
                  block.settings.toInitiator &&
                  this.processActions.indexOf("To Requester") === -1
                ) {
                  this.processActions.unshift("To Requester");
                  this.toRequester = true;
                }
                if (block.settings.internalForward) {
                  if (this.isTenantGOTO()) {
                    this.processActions.unshift("Assign");
                  } else {
                    this.processActions.unshift("Forward");
                  }
                  this.internalForward.proceed = true;
                  this.internalForward.users = [];
                  this.internalForward.groups = [];
                  if (block.settings.internalForwardUser) {
                    if (block.settings.internalForwardUser.length) {
                      block.settings.internalForwardUser.forEach((user) => {
                        let userFilter = this.userList.filter(
                          (row) => row.value == user
                        );
                        if (userFilter.length) {
                          this.internalForward.users.push({
                            id: this.$nano.id(),
                            label: userFilter[0].label,
                            value: user,
                          });
                        }
                      });
                    }
                  }
                  if (block.settings.internalForwardGroup) {
                    if (block.settings.internalForwardGroup.length) {
                      block.settings.internalForwardGroup.forEach((group) => {
                        let groupFilter = this.groupList.filter(
                          (row) => row.value == group
                        );
                        if (groupFilter.length) {
                          this.internalForward.groups.push({
                            id: this.$nano.id(),
                            label: groupFilter[0].label,
                            value: group,
                          });
                        }
                      });
                    }
                  }
                }
              }

              this.userSignature = block.settings.userSignature;
              if (block.settings.fileSettings) {
                if (block.settings.fileSettings.fileCheckList.length) {
                  this.checkList = block.settings.fileSettings.fileCheckList;
                  this.checkList.forEach((row) => {
                    row.uid = this.$nano.id();
                    row.attach = false;
                  });
                }
              }
            }
          });
          if (this.selectedProcess.userType) {
            if (this.selectedProcess.userType === "SUPERUSER") {
              //this.processActions = [];
              this.processActions.push("Force Close");
            }
          }
        } else if (this.selectedNode === "sent") {
          if (this.isTenantGOTO()) {
            if (
              this.selectedProcess.transaction_createdBy ===
              this.$store.state.session.id
            ) {
              this.processActions = [];
              this.processActions.push("Rollback");
            }
          } else if (
            origin === "http://demo.ezofis.com" ||
            origin === "http://52.172.32.88"
          ) {
            if (this.selectedWorkflow.id === 6) {
              if (
                this.selectedProcess.transaction_createdBy ===
                this.$store.state.session.id
              ) {
                this.processActions = [];
                this.processActions.push("Rollback");
              }
            }
          }
        }
        if (this.selectedProcess.flowStatus === "1") {
          let wflowJson;
          wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
          let actionBlock = wflowJson.blocks.find(
            (block) => block.id === this.selectedProcess.activityId
          );
          if (actionBlock) {
            if (actionBlock.settings.reopen) {
              this.processActions = [];
              this.processActions.push("Reopen");
            }
          }
        }
        if (
          this.selectedWorkflow.wFormId &&
          Object.keys(this.form).length === 0
        ) {
          await this.getForm();
        }
        this.getTaskList();
      }
      if (requestView) {
        if (
          (this.selectedNode === "inbox" ||
            this.selectedNode === "common" ||
            this.selectedNode === "payment" ||
            this.myInboxShow) &&
          this.selectedProcess.hasTicketMultiUser
        ) {
          console.log(this.selectedProcess);
          this.fullView = true;
          //this.checkTicketLock();
          // if (
          //   this.selectedProcess.ticketLockedBy === this.$store.state.session.id
          // ) {
          //   this.fullView = true;
          // } else if (
          //   this.selectedProcess.ticketLockedBy &&
          //   this.selectedProcess.ticketLockedBy !== "0"
          // ) {
          //   //this.requestLockedModal = true;
          //   this.isRequestLocked = true;
          //   this.fullView = true;
          // } else {
          //   //this.requestForLockModal = true;
          //   this.updateRequestReserved();
          // }
        } else {
          this.fullView = true;
        }
      }
    },

    async releaseReservedRequest(process) {
      let input = {
        workflowId: process.id,
        processId: process.processId,
        activityId: process.activityId,
        isLock: false,
      };
      const { error } = await workflow.updateRequestReserved(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
    },

    async updateRequestReserved() {
      let input = {
        workflowId: this.selectedProcess.id,
        processId: this.selectedProcess.processId,
        activityId: this.selectedProcess.activityId,
        isLock: true,
      };
      const { error } = await workflow.updateRequestReserved(input);

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.requestForLockModal = false;
      this.selectedProcess.ticketLockedBy = this.$store.state.session.id;
      this.fullView = true;
    },

    async getAppConnection() {
      this.jiraConnect = false;
      const { error, payload } = await integration.getJiraConnection({
        filterBy: [],
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      payload.forEach((row) => {
        if (row.cloudName === "jira") {
          this.jiraAccounts.push(row);
        }
      });
    },

    async getUserMyInbox() {
      const { error, payload } = await workflow.getUserMyInbox();

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.myInboxCount = Number(payload);
      }
    },

    async getUserWorkflowTest() {
      this.refreshTree = true;
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflowTest();
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.workflowList = payload;
      }
    },

    async getUserWorkflow() {
      this.refreshTree = true;
      this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getUserWorkflow();
      this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.workflowList = payload;
      }
    },

    async getMyInboxListGOTO() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      //this.$store.commit("showLoadingBarPage");
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };
      const { error, payload } = await workflow.getMyInboxList(
        filterBy,
        this.selectedWorkflow.id
      );
      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarInbox = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      //if (payload.meta.totalItems) {
      this.totalItems = 0;
      payload.data.forEach((row) => {
        if (row.key) {
          if (this.totalItems < row.totalCount) {
            this.totalItems = row.totalCount;
          }
        } else {
          this.totalItems = payload.meta.totalItems;
        }
        let listAction = [];
        let actionFields = [];
        if (row.key && row.value.length) {
          let wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
          wflowJson.rules.forEach((rule) => {
            if (rule.fromBlockId === row.value[0].activityId) {
              let icon = "",
                color = "";
              switch (rule.proceedAction) {
                case "Forward":
                case "Reply":
                case "Assign":
                  icon = "mdi-arrow-right";
                  color = "orange";
                  break;
                case "Approve":
                case "Complete":
                case "Rightsize":
                  icon = "mdi-check";
                  color = "positive";
                  break;
                case "Reject":
                case "Terminate":
                  icon = "mdi-close";
                  color = "red";
                  break;
                default:
                  icon = "mdi-arrow-left";
                  color = "primary";
                  break;
              }
              listAction.push({
                id: this.$nano.id(),
                name: rule.proceedAction,
                icon: icon,
                color: color,
              });
            }
          });
          wflowJson.blocks.forEach((block) => {
            if (block.id === row.value[0].activityId) {
              if (block.settings.formEditPartialAccess) {
                actionFields = block.settings.formEditControls;
              }
              if (block.settings.internalForward) {
                this.internalForward.proceed = true;
                this.internalForward.users = [];
                this.internalForward.groups = [];
                listAction.push({
                  id: this.$nano.id(),
                  name: "Forward",
                  icon: "mdi-arrow-right",
                  color: "orange",
                });
              }
            }
          });
        }
        if (this.currentPage === 1) {
          this.groupKey.push({
            name: row.key,
            checked: false,
            expand: true,
            actions: listAction,
            actionFields: actionFields,
            totalCount: row.totalCount,
            checkboxRequired: true,
          });
        }
        row.value.forEach((process) => {
          this.workflowRequestList.push({
            key: row.key,
            ...process,
            checked: false,
            starred: false,
            visible: true,
            checkboxRequired: true,
          });
        });
      });
      //}
    },

    removeTimestampFromString(str) {
      return str
        .replace(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/, "")
        .trim();
    },

    async getMyInboxList() {
      this.dashboard = false;
      let List = this.listedData.map((filter) => ({
        id: this.$nano.id(),
        label: filter.name,
        filterJson: JSON.parse(filter.filterJson),
        dashboard: false,
      }));

      List.forEach((item) => {
        const types = item.filterJson[0].node.split("-");
        let node = types[0];
        let Jsonfiltering = "";
        let filtersArray = "";
        let filterBy = this.filterBy;
        if (filterBy.length) {
          for (let i = 0; i < filterBy.length; i++) {
            let item = filterBy[i];
            if ("id" in item) {
              delete item["id"];
            }
            if (Array.isArray(item.filters)) {
              for (let j = 0; j < item.filters.length; j++) {
                let filter = item.filters[j];
                if (typeof filter.value === "string") {
                  filter.value = filter.value.toLowerCase();
                }
                if ("id" in filter) {
                  delete filter["id"];
                }
              }
            }
          }
          Jsonfiltering = JSON.stringify(filterBy, null, 2);
        } else if (!filterBy.length) {
          Jsonfiltering = JSON.stringify(filterBy);
        }
        let parsedData = JSON.parse(item.filterJson[0].filters);
        if (parsedData.length) {
          for (let i = 0; i < parsedData.length; i++) {
            let item = parsedData[i];
            if ("id" in item) {
              delete item["id"];
            }
            if (Array.isArray(item.filters)) {
              for (let j = 0; j < item.filters.length; j++) {
                let filter = item.filters[j];
                if (typeof filter.value === "string") {
                  filter.value = filter.value.toLowerCase();
                }
                if ("id" in filter) {
                  delete filter["id"];
                }
              }
            }
          }
          filtersArray = JSON.stringify(parsedData, null, 2);
        } else if (!parsedData.length) {
          filtersArray = item.filterJson[0].filters;
        }
        let findValuenames = this.removeTimestampFromString(item.label);
        const parts = findValuenames.split(" ");
        let findValuename = parts.slice(0, -1).join(" ").trim();
        if (
          findValuename === "My Inbox" &&
          node === this.selectedNode &&
          filtersArray === Jsonfiltering
        ) {
          this.dashboard = true;
        }
      });
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      //this.$store.commit("showLoadingBarPage");
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        groupBy: this.isTenantArmgroup() ? ["bum5Q2XCpYIzQWsW5NFkw"] : [],
      };
      const { error, payload } = await workflow.getMyInboxList(filterBy);
      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarInbox = false;
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      this.totalItems = payload.meta.totalItems;
      payload.data.forEach((row) => {
        let listAction = [];
        let actionFields = [];
        if (row.key && row.value.length) {
          this.selectedWorkflow = this.workflowList.find(
            (workflow) => workflow.id == row.workflowId
          );
          let wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
          wflowJson.rules.forEach((rule) => {
            if (rule.fromBlockId === row.value[0].activityId) {
              let icon = "",
                color = "";
              switch (rule.proceedAction) {
                case "Forward":
                case "Reply":
                  icon = "mdi-arrow-right";
                  color = "orange";
                  break;
                case "Approve":
                case "Complete":
                case "Rightsize":
                  icon = "mdi-check";
                  color = "positive";
                  break;
                case "Reject":
                case "Terminate":
                  icon = "mdi-close";
                  color = "red";
                  break;
                default:
                  icon = "mdi-arrow-left";
                  color = "primary";
                  break;
              }
              listAction.push({
                id: this.$nano.id(),
                name: rule.proceedAction,
                icon: icon,
                color: color,
              });
            }
          });
          wflowJson.blocks.forEach((block) => {
            if (block.id === row.value[0].activityId) {
              if (block.settings.formEditPartialAccess) {
                actionFields = block.settings.formEditControls;
              }
              if (block.settings.internalForward) {
                this.internalForward.proceed = true;
                this.internalForward.users = [];
                this.internalForward.groups = [];
                listAction.push({
                  id: this.$nano.id(),
                  name: "Forward",
                  icon: "mdi-arrow-right",
                  color: "orange",
                });
              }
            }
          });
        }
        //console.log(row);
        let checkboxRequired = false;
        if (this.isTenantGOTO()) {
          checkboxRequired = true;
        }
        if (this.currentPage === 1) {
          this.groupKey.push({
            name: row.key,
            checked: false,
            expand: true,
            actions: listAction,
            actionFields: actionFields,
            totalCount: row.totalCount,
            checkboxRequired: checkboxRequired,
            subKey:
              row.value.length && row.value[0].key
                ? row.value.map((sub) => ({
                    name: sub.key,
                    checked: false,
                    expand: true,
                    totalCount: sub.totalCount,
                  }))
                : [
                    {
                      name: "",
                      checked: false,
                      expand: true,
                      totalCount: 0,
                    },
                  ],
          });
        } else {
          // this.groupKey.forEach((group) => {
          //   group.subKey.forEach((subGroup) => {
          //     if (subGroup.name === row.value[0].key) {
          //       subGroup.totalCount += row.value[0].totalCount;
          //     }
          //   });
          // });
        }
        row.value.forEach((group) => {
          group.value.forEach((process) => {
            this.workflowRequestList.push({
              key: row.key,
              subKey: group.key,
              ...process,
              checked: false,
              starred: false,
              visible: true,
              checkboxRequired: checkboxRequired,
            });
          });
        });
      });
    },

    async getCommonInbox() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        groupBy: this.isTenantArmgroup() ? ["bum5Q2XCpYIzQWsW5NFkw"] : [],
      };
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getCommonList(
        this.selectedWorkflow.id,
        filterBy
      );
      this.loadingBarInbox = false;
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload.meta.totalItems) {
        this.totalItems = payload.meta.totalItems;
        payload.data.forEach((row) => {
          if (this.currentPage === 1) {
            this.groupKey.push({
              name: row.key,
              checked: false,
              expand: true,
              totalCount: row.totalCount,
              subKey:
                row.value.length && row.value[0].key
                  ? row.value.map((sub) => ({
                      name: sub.key,
                      checked: false,
                      expand: false,
                      totalCount: sub.totalCount,
                    }))
                  : [
                      {
                        name: "",
                        checked: false,
                        expand: true,
                        totalCount: 0,
                      },
                    ],
            });
          } else {
            // this.groupKey.forEach((group) => {
            //   group.subKey.forEach((subGroup) => {
            //     if (subGroup.name === row.value[0].key) {
            //       subGroup.totalCount += row.value[0].totalCount;
            //     }
            //   });
            // });
          }
          row.value.forEach((process) => {
            if (process.value && process.value.length) {
              process.value.forEach((req) => {
                delete req["key"];
                this.workflowRequestList.push({
                  key: row.key,
                  subKey: process.key,
                  ...req,
                  checked: false,
                  starred: false,
                  visible: true,
                });
              });
            } else {
              this.workflowRequestList.push({
                key: row.key,
                ...process,
                checked: false,
                starred: false,
                visible: true,
              });
            }
          });
        });
      }
    },

    async getInboxListTest() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getInboxListTest(
        this.selectedWorkflow.id,
        filterBy
      );
      this.loadingBarInbox = false;
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload.meta.totalItems) {
        this.totalItems = 0;
        payload.data.forEach((row) => {
          this.totalItems += row.totalCount;
          let listAction = [];
          let actionFields = [];
          if (row.key && row.value.length) {
            let wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === row.value[0].activityId) {
                let icon = "",
                  color = "";
                switch (rule.proceedAction) {
                  case "Forward":
                  case "Reply":
                    icon = "mdi-arrow-right";
                    color = "orange";
                    break;
                  case "Approve":
                  case "Complete":
                  case "Rightsize":
                    icon = "mdi-check";
                    color = "positive";
                    break;
                  case "Reject":
                  case "Terminate":
                    icon = "mdi-close";
                    color = "red";
                    break;
                  default:
                    icon = "mdi-arrow-left";
                    color = "primary";
                    break;
                }
                listAction.push({
                  id: this.$nano.id(),
                  name: rule.proceedAction,
                  icon: icon,
                  color: color,
                });
              }
            });
            wflowJson.blocks.forEach((block) => {
              if (block.id === row.value[0].activityId) {
                if (block.settings.formEditPartialAccess) {
                  actionFields = block.settings.formEditControls;
                }
                if (block.settings.internalForward) {
                  this.internalForward.proceed = true;
                  this.internalForward.users = [];
                  this.internalForward.groups = [];
                  listAction.push({
                    id: this.$nano.id(),
                    name: "Assign",
                    icon: "mdi-arrow-right",
                    color: "orange",
                  });
                }
              }
            });
          }
          let checkboxRequired = false;
          if (this.isTenantGOTO()) {
            checkboxRequired = true;
          }
          if (this.currentPage === 1) {
            this.groupKey.push({
              name: row.key,
              checked: false,
              expand: true,
              actions: listAction,
              actionFields: actionFields,
              totalCount: row.totalCount,
              checkboxRequired: checkboxRequired,
              subKey: row.value.length
                ? row.value.map((sub) => ({
                    name: sub.key,
                    checked: false,
                    expand: true,
                    actions: listAction,
                    actionFields: actionFields,
                    totalCount: sub.totalCount,
                    checkboxRequired: checkboxRequired,
                  }))
                : [
                    {
                      name: "",
                      checked: false,
                      expand: true,
                      totalCount: 0,
                    },
                  ],
            });
          }
          row.value.forEach((process) => {
            // this.workflowRequestList.push({
            //   key: row.key,
            //   ...process,
            //   checked: false,
            //   starred: false,
            //   visible: true,
            //   checkboxRequired: checkboxRequired,
            // });
            process.value.forEach((req) => {
              delete req["key"];
              this.workflowRequestList.push({
                key: row.key,
                subKey: process.key,
                ...req,
                checked: false,
                starred: false,
                visible: true,
                checkboxRequired: checkboxRequired,
              });
            });
          });
        });
      }
    },

    async getInboxList() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        groupBy: this.isTenantArmgroup() ? ["bum5Q2XCpYIzQWsW5NFkw"] : [],
      };
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getInboxList(
        this.selectedWorkflow.id,
        filterBy
      );
      this.loadingBarInbox = false;
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload.meta.totalItems) {
        this.totalItems = 0;
        payload.data.forEach((row) => {
          this.totalItems += row.totalCount;
          let listAction = [];
          let actionFields = [];
          if (row.key && row.value.length) {
            let wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === row.value[0].activityId) {
                let icon = "",
                  color = "";
                switch (rule.proceedAction) {
                  case "Forward":
                  case "Reply":
                  case "Assign":
                    icon = "mdi-arrow-right";
                    color = "orange";
                    break;
                  case "Approve":
                  case "Complete":
                  case "Rightsize":
                    icon = "mdi-check";
                    color = "positive";
                    break;
                  case "Reject":
                  case "Terminate":
                    icon = "mdi-close";
                    color = "red";
                    break;
                  default:
                    icon = "mdi-arrow-left";
                    color = "primary";
                    break;
                }
                listAction.push({
                  id: this.$nano.id(),
                  name: rule.proceedAction,
                  icon: icon,
                  color: color,
                });
              }
            });
            wflowJson.blocks.forEach((block) => {
              if (block.id === row.value[0].activityId) {
                if (block.settings.formEditPartialAccess) {
                  actionFields = block.settings.formEditControls;
                }
                if (block.settings.internalForward) {
                  this.internalForward.proceed = true;
                  this.internalForward.users = [];
                  this.internalForward.groups = [];
                  listAction.push({
                    id: this.$nano.id(),
                    name: "Assign",
                    icon: "mdi-arrow-right",
                    color: "orange",
                  });
                }
              }
            });
          }
          let checkboxRequired = false;
          if (this.isTenantGOTO()) {
            checkboxRequired = true;
          }
          if (this.currentPage === 1) {
            if (this.isTenantArmgroup()) {
              listAction = [];
            }
            this.groupKey.push({
              name: row.key,
              checked: false,
              expand: true,
              actions: listAction,
              actionFields: actionFields,
              totalCount: row.totalCount,
              checkboxRequired: checkboxRequired,
              subKey:
                row.value.length && row.value[0].key
                  ? row.value.map((sub) => ({
                      name: sub.key,
                      checked: false,
                      expand: false,
                      actions: listAction,
                      actionFields: actionFields,
                      totalCount: sub.totalCount,
                      checkboxRequired: checkboxRequired,
                    }))
                  : [
                      {
                        name: "",
                        checked: false,
                        expand: true,
                        totalCount: 0,
                      },
                    ],
            });
          } else {
            // this.groupKey.forEach((group) => {
            //   group.subKey.forEach((subGroup) => {
            //     if (subGroup.name === row.value[0].key) {
            //       subGroup.totalCount += row.value[0].totalCount;
            //     }
            //   });
            // });
          }
          row.value.forEach((process) => {
            checkboxRequired = false;
            if (this.isTenantArmgroup()) {
              if (process.activityId === "szS6B-q1x9acpB83rP6jT") {
                checkboxRequired = true;
              }
            }
            if (process.value && process.value.length) {
              process.value.forEach((req) => {
                delete req["key"];
                this.workflowRequestList.push({
                  key: row.key,
                  subKey: process.key,
                  ...req,
                  checked: false,
                  starred: false,
                  visible: true,
                  inbox: true,
                  checkboxRequired: checkboxRequired,
                });
              });
            } else {
              this.workflowRequestList.push({
                key: row.key,
                ...process,
                checked: false,
                starred: false,
                visible: true,
                inbox: true,
                checkboxRequired: checkboxRequired,
                expandSubWorkflow: false,
              });
            }
          });
        });
      }
    },

    async getSentListTest() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };
      // this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workflow.getSentListTest(
        this.selectedWorkflow.id,
        filterBy
      );
      this.loadingBarInbox = false;

      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload) {
        this.totalItems = payload.meta.totalItems;
        if (payload.data.length) {
          // console.log(payload);
          payload.data.forEach((row) => {
            if (this.currentPage === 1) {
              this.groupKey.push({
                name: row.key,
                expand: true,
                totalCount: row.totalCount,
                subKey:
                  row.value && row.value.length && row.value[0].key
                    ? row.value.map((sub) => ({
                        name: sub.key,
                        expand: true,
                        totalCount: sub.totalCount,
                      }))
                    : [
                        {
                          name: "",
                          expand: true,
                          totalCount: 0,
                        },
                      ],
              });
            }
            row.value.forEach((process) => {
              if (process.value && process.value.length) {
                process.value.forEach((req) => {
                  delete req["key"];
                  this.workflowRequestList.push({
                    key: row.key,
                    subKey: process.key,
                    ...req,
                    starred: false,
                    visible: true,
                  });
                });
              } else {
                this.workflowRequestList.push({
                  key: "",
                  ...process,
                  starred: false,
                  visible: true,
                });
              }
            });
          });
        }
      }
    },

    async getSentList() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
      };
      // this.$store.commit("showLoadingBarPage");

      const { error, payload } = await workflow.getSentList(
        this.selectedWorkflow.id,
        filterBy
      );
      this.loadingBarInbox = false;

      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload) {
        if (this.currentPage === 1) {
          this.groupKey.push({
            name: "",
            checked: false,
            expand: true,
            subKey: [
              {
                name: "",
                checked: false,
                expand: true,
                totalCount: 0,
              },
            ],
          });
        }
        this.totalItems = payload.meta.totalItems;
        if (payload.data.length) {
          payload.data[0].value.forEach((process) => {
            this.workflowRequestList.push({
              key: "",
              ...process,
              starred: false,
              visible: true,
              expandSubWorkflow: false,
            });
          });
        }
      }
    },

    async getCompletedListTest() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      //this.$store.commit("showLoadingBarPage");
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        filterValue: this.selectedCompletedNode,
      };
      const { error, payload } = await workflow.getCompletedListTest(
        this.selectedWorkflow.id,
        filterBy
      );
      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarInbox = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload) {
        this.totalItems = payload.meta.totalItems;
        if (this.selectedCompletedNode) {
          this.totalItems = this.selectedWorkflow.completedInfo.find(
            (row) => row.value === this.selectedCompletedNode
          )?.totalCount;
        }
        if (payload.data.length) {
          payload.data.forEach((row) => {
            if (this.currentPage === 1) {
              this.groupKey.push({
                name: row.key,
                expand: true,
                totalCount: row.totalCount,
                subKey:
                  row.value.length && row.value[0].key
                    ? row.value.map((sub) => ({
                        name: sub.key,
                        expand: true,
                        totalCount: sub.totalCount,
                      }))
                    : [
                        {
                          name: "",
                          expand: true,
                          totalCount: 0,
                        },
                      ],
              });
            }
            row.value.forEach((process) => {
              if (process.value && process.value.length) {
                process.value.forEach((req) => {
                  delete req["key"];
                  this.workflowRequestList.push({
                    key: row.key,
                    subKey: process.key,
                    ...req,
                    starred: false,
                    visible: true,
                  });
                });
              } else {
                this.workflowRequestList.push({
                  key: "",
                  ...process,
                  starred: false,
                  visible: true,
                });
              }
            });
          });
        }
      }
    },

    async getCompletedList() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      //this.$store.commit("showLoadingBarPage");
      let filterBy = {
        sortBy: this.sortBy,
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        filterValue: this.selectedCompletedNode,
      };
      const { error, payload } = await workflow.getCompletedList(
        this.selectedWorkflow.id,
        filterBy
      );
      //this.$store.commit("hideLoadingBarPage");
      this.loadingBarInbox = false;

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload) {
        if (this.currentPage === 1) {
          this.groupKey.push({
            name: "",
            checked: false,
            expand: true,
            subKey: [
              {
                name: "",
                checked: false,
                expand: true,
                totalCount: 0,
              },
            ],
          });
        }
        this.totalItems = payload.meta.totalItems;

        if (this.selectedCompletedNode) {
          this.totalItems = this.selectedWorkflow.completedInfo.find(
            (row) => row.value === this.selectedCompletedNode
          )?.totalCount;
        }
        if (payload.data.length) {
          payload.data[0].value.forEach((process) => {
            this.workflowRequestList.push({
              key: "",
              ...process,
              starred: false,
              visible: true,
              expandSubWorkflow: false,
            });
          });
        }
      }
    },

    async getPaymentList() {
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
        this.loadingBarInbox = true;
      }
      let filterBy = {
        filterBy: this.filterBy,
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        groupBy: this.isTenantArmgroup() ? ["bum5Q2XCpYIzQWsW5NFkw"] : [],
      };
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await workflow.getPaymentList(
        this.selectedWorkflow.id,
        filterBy
      );
      this.loadingBarInbox = false;
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (this.currentPage === 1) {
        this.groupKey = [];
        this.workflowRequestList = [];
      }
      if (payload.meta.totalItems) {
        this.totalItems = 0;
        payload.data.forEach((row) => {
          this.totalItems += row.totalCount;
          let listAction = [];
          let actionFields = [];
          if (row.key && row.value.length) {
            let wflowJson = JSON.parse(this.selectedWorkflow.flowJson);
            wflowJson.rules.forEach((rule) => {
              if (rule.fromBlockId === row.value[0].activityId) {
                let icon = "",
                  color = "";
                switch (rule.proceedAction) {
                  case "Forward":
                  case "Reply":
                    icon = "mdi-arrow-right";
                    color = "orange";
                    break;
                  case "Approve":
                  case "Complete":
                  case "Rightsize":
                    icon = "mdi-check";
                    color = "positive";
                    break;
                  case "Reject":
                  case "Terminate":
                    icon = "mdi-close";
                    color = "red";
                    break;
                  default:
                    icon = "mdi-arrow-left";
                    color = "primary";
                    break;
                }
                listAction.push({
                  id: this.$nano.id(),
                  name: rule.proceedAction,
                  icon: icon,
                  color: color,
                });
              }
            });
            wflowJson.blocks.forEach((block) => {
              if (block.id === row.value[0].activityId) {
                if (block.settings.formEditPartialAccess) {
                  actionFields = block.settings.formEditControls;
                }
                if (block.settings.internalForward) {
                  this.internalForward.proceed = true;
                  this.internalForward.users = [];
                  this.internalForward.groups = [];
                  listAction.push({
                    id: this.$nano.id(),
                    name: "Assign",
                    icon: "mdi-arrow-right",
                    color: "orange",
                  });
                }
              }
            });
          }
          let checkboxRequired = false;
          if (this.isTenantGOTO()) {
            checkboxRequired = true;
          }
          if (this.currentPage === 1) {
            this.groupKey.push({
              name: row.key,
              checked: false,
              expand: true,
              actions: listAction,
              actionFields: actionFields,
              totalCount: row.totalCount,
              checkboxRequired: checkboxRequired,
              subKey:
                row.value.length && row.value[0].key
                  ? row.value.map((sub) => ({
                      name: sub.key,
                      expand: false,
                      totalCount: sub.totalCount,
                    }))
                  : [
                      {
                        name: "",
                        expand: true,
                        totalCount: 0,
                        checkboxRequired: checkboxRequired,
                      },
                    ],
            });
          } else {
            // this.groupKey.forEach((group) => {
            //   group.subKey.forEach((subGroup) => {
            //     if (subGroup.name === row.value[0].key) {
            //       subGroup.totalCount += row.value[0].totalCount;
            //     }
            //   });
            // });
          }
          row.value.forEach((process) => {
            if (process.value && process.value.length) {
              process.value.forEach((req) => {
                delete req["key"];
                this.workflowRequestList.push({
                  key: row.key,
                  subKey: process.key,
                  ...req,
                  starred: false,
                  visible: true,
                  checked: false,
                  checkboxRequired: checkboxRequired,
                });
              });
            } else {
              this.workflowRequestList.push({
                key: row.key,
                ...process,
                checked: false,
                starred: false,
                visible: true,
                checkboxRequired: checkboxRequired,
              });
            }
          });
        });
      }
    },

    async getForm() {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getForm(
        this.selectedWorkflow.wFormId
      );
      //this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.form = payload;
      }
    },

    async getRepository() {
      const { error, payload } = await repository.getRepository(
        this.selectedWorkflow.repositoryId
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.repository = payload;
      }
    },

    async requestAction(action, forwardCheck = true, addOnCheck = true) {
      if (forwardCheck) {
        if (this.isTenantGOTO()) {
          if (action === "Assign" && this.internalForward.proceed) {
            this.processForwardModal = true;
            return;
          }
        }
        if (action === "Forward" && this.internalForward.proceed) {
          this.processForwardModal = true;
          return;
        }

        if (this.isTenantArmgroup()) {
          if (action === "Assign") {
            this.processForwardModal = true;
            return;
          }
        }
      }

      if (addOnCheck) {
        let actionAddOn = this.processActionsAddOn.find(
          (row) => row.action === action
        );
        if (actionAddOn) {
          if (Object.keys(actionAddOn).length) {
            this.actionRemarks = actionAddOn.remarks;
            this.actionPassword = actionAddOn.passwordAccess;
            this.actionConfirm = actionAddOn.confirm;
            if (this.actionPassword) {
              this.actionText = action;
              this.actionPasswordConfirm = true;
              return;
            }
            if (this.actionRemarks) {
              this.actionText = action;
              this.advancedCommentsSheet = true;
              return;
            }
            if (this.actionConfirm) {
              this.actionText = action;
              this.actionConfirmAlert = true;
              return;
            }
          }
        }
      }

      let origin = location.origin;
      if (this.isTenantGOTO() && action === "Rollback") {
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      } else if (
        origin === "http://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.selectedWorkflow.id === 6) {
          this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
        }
      }
      if (this.customReply && action.indexOf("Reply") > -1) {
        action = "Reply";
        this.forwardUsers.push(this.selectedProcess.transaction_createdBy);
      }

      if (this.userReply && action.indexOf("Reply") > -1) {
        action = "UserReply";
      } else if (this.toRequester && action === "To Requester") {
        action = "ToInitiator";
      }

      this.selectedProcess.review = action;
      let inputData;
      if (
        (this.isTenantArmgroup() ||
          (origin === "https://trial.ezofis.com" &&
            this.$store.state.session.tenantId === 12)) &&
        this.selectedWorkflow.id === 1 &&
        (action === "Recommend to Approve" || action === "Recommend to Reject")
      ) {
        inputData = {
          workflowId: this.selectedWorkflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: action,
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      } else {
        inputData = {
          workflowId: this.selectedWorkflow.id,
          transactionId: this.selectedProcess.transactionId,
          review: action,
          formData: this.selectedProcess.formData,
          userIds: this.forwardUsers,
          groupIds: this.forwardGroups,
        };
      }

      console.log(inputData);

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }

      console.log(payload);
      if (inputData.review === "") {
        this.$alert.info(
          `${this.selectedProcess.requestNo} data saved, awaiting your action to submit`
        );
      } else if (inputData.review.toLowerCase().indexOf("reject") > -1) {
        this.$alert.danger(
          `${this.selectedProcess.requestNo} Request Rejected Successfully`
        );
      } else {
        this.$alert.success(
          `${this.selectedProcess.requestNo} Request Processed`
        );
      }
      this.selectedProcess = {};
      this.processActions = [];
      this.processActionsAddOn = [];
      this.customReply = false;
      this.userReply = false;
      this.toRequester = false;
      this.forwardUsers = [];
      this.fullView = false;
      this.refresh();
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      if (payload) {
        payload.map((user) => {
          if (this.isTenantArmgroup()) {
            if (user.id !== "1") {
              this.userList.push({
                id: this.$nano.id(),
                label: user.value || user.loginName,
                value: user.id,
                firstName: user.firstName,
                lastName: user.lastName || "",
              });
            }
          } else if (user.id !== this.$store.state.session.id)
            this.userList.push({
              id: this.$nano.id(),
              label: user.value || user.loginName,
              value: user.id,
            });
        });
      }
    },

    async getGroups() {
      const { error, payload } = await group.getGroupList();

      if (error) {
        this.$alert.error("Error fetching groups");
        return;
      }

      this.groupList =
        payload &&
        payload.map((group) => ({
          id: this.$nano.id(),
          label: group.value,
          value: group.id,
        }));
    },

    async uploadFile(e) {
      const file = e.target.files[0];
      if (
        file.type === "text/csv" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const formData = new FormData();
        formData.append("formId", this.masterFormId);
        formData.append("file", file);
        try {
          this.$store.commit("showLoadingBarSave");
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/form/uploadMasterFile`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          this.$store.commit("hideLoadingBarSave");
          const { status, data } = response;
          if (status === 200 && data)
            this.$alert.success(`Data imported successfully`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }

        this.dataImported = true;
        e.target.value = "";
      } else {
        this.$alert.error("Please upload a csv/excel file");
      }
    },

    async getTaskForm() {
      this.taskFormList = [];
      this.addTaskEnabled = false;
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "Task",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data } = payload;
      if (data.length) {
        if (data[0].value.length) {
          this.addTaskEnabled = true;
          data[0].value.forEach((task) => {
            this.taskFormList.push({
              id: task.id,
              label: task.name,
              value: task.id,
              repositoryId: task.repositoryId,
              formJson: task.formJson,
            });
          });
          if (this.taskFormList.length) {
            if (this.taskFormList.length === 1) {
              this.taskFormId = this.taskFormList[0].id;
              this.taskRepositoryId = this.taskFormList[0].repositoryId;
              this.addTaskForm = JSON.parse(this.taskFormList[0].formJson);
            }
          } else {
            //this.$alert.info("Task form not found");
          }
        }
      }
    },

    async getTaskFormEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error, payload } = await form.getFormEntry(formId, entryId);
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];

        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskName = "",
          taskAssign = "",
          startDate = "",
          endDate = "",
          priority = "",
          status = "";
        formFields.forEach((field) => {
          switch (field.label) {
            case "Task Name":
              taskName = payload[0][field.id];
              break;
            case "Task Assign":
              taskAssign = payload[0][field.id];
              break;
            case "Start Date":
              startDate = payload[0][field.id];
              break;
            case "End Date":
              endDate = payload[0][field.id];
              break;
            case "Priority":
              priority = payload[0][field.id];
              break;
            case "Task Status":
              status = payload[0][field.id];
              break;
          }
        });
        this.taskEntries.push({
          id: payload[0].itemid,
          name: taskName,
          assigned: taskAssign,
          startDate: startDate,
          endDate: endDate,
          priority: priority,
          status: status,
        });
      }
    },

    async getTaskList() {
      this.taskEntries = [];
      let taskFilterBy = [];
      if (Object.keys(this.addTaskForm).length) {
        const panels = [
          ...this.addTaskForm.panels,
          ...this.addTaskForm.secondaryPanels,
        ];
        let formFields = [];
        for (const panel of panels) {
          formFields.push(...panel.fields);
        }
        let taskOwnerField = formFields.find(
          (field) => field.label === "Task Owner"
        );
        if (taskOwnerField) {
          taskFilterBy.push({
            filters: [
              {
                criteria: taskOwnerField.id,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
              },
            ],
            groupCondition: "OR",
          });
        }
        let taskAssignField = formFields.find(
          (field) => field.label === "Task Assign"
        );
        if (taskAssignField) {
          taskFilterBy.push({
            filters: [
              {
                criteria: taskAssignField.id,
                condition: "IS_EQUALS_TO",
                value: this.$store.state.session.email,
              },
            ],
            groupCondition: "OR",
          });
        }
        //this.$store.commit("showLoadingBar");
        const { error, payload } = await workflow.getTaskEntries(
          this.selectedWorkflow.id,
          this.selectedProcess.processId,
          {
            filterBy: [
              {
                filters: [
                  {
                    criteria: "createdBy",
                    condition: "IS_EQUALS_TO",
                    value: this.$store.state.session.id,
                  },
                ],
              },
              ...taskFilterBy,
            ],
          }
        );
        //this.$store.commit("hideLoadingBar");

        if (error) {
          this.$alert.error(error);
          return;
        }
        if (payload.length) {
          this.taskList = payload;
          payload.forEach((row) => {
            let taskName = "",
              taskAssign = "",
              startDate = "",
              endDate = "",
              priority = "",
              status = "";
            formFields.forEach((field) => {
              switch (field.label) {
                case "Task Name":
                  taskName = row.entryInfo[0][field.id];
                  break;
                case "Task Assign":
                  taskAssign = row.entryInfo[0][field.id];
                  break;
                case "Start Date":
                  startDate = row.entryInfo[0][field.id];
                  break;
                case "End Date":
                  endDate = row.entryInfo[0][field.id];
                  break;
                case "Priority":
                  priority = row.entryInfo[0][field.id];
                  break;
                case "Task Status":
                  status = row.entryInfo[0][field.id];
                  break;
              }
            });
            this.taskEntries.push({
              id: row.entryInfo[0].itemid,
              name: taskName,
              assigned: taskAssign,
              startDate: startDate,
              endDate: endDate,
              priority: priority,
              status: status,
            });
          });
        }
      }
    },

    async insertTaskEntry(formId, entryId) {
      // this.$store.commit("showLoadingBarPage");
      const { error } = await workflow.insertTaskEntry({
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        transactionId: this.selectedProcess.transactionId,
        wFormId: formId,
        formEntryId: entryId,
      });
      // this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.getTaskList();
    },

    async dataValidation(processId, modal = true) {
      //this.$store.commit("showLoadingBarPage");
      const { error, payload } = await custom.armgroupMLValidation({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });
      //this.$store.commit("hideLoadingBarPage");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.dataValidationValues = payload;
        if (modal) {
          this.dataValidationModal = true;
        }
      } else {
        this.dataValidationValues = [];
        if (modal) {
          this.$alert.info("Data not found");
        }
      }
    },

    async showPaymentTable() {
      this.totalPayment = 0;
      this.lumpsumAmount = 0;
      this.benefitType = "";
      this.numberOfApprovePayment = 0;
      this.paymentTableValues = [];
      await this.getPaymentTable();
      this.paymentTableModal = true;
    },

    async getPaymentTable() {
      this.isPaymentFound = false;
      const { error, payload } = await custom.getPaymentTable({
        filterBy: [
          {
            filters: [
              {
                criteria: "processId",
                condition: "IS_EQUALS_TO",
                value: this.selectedProcess.processId,
              },
            ],
            groupCondition: "",
          },
        ],
        mode: "BROWSE",
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.paymentTableValues = payload;
        this.isPaymentFound = true;
      }
    },

    export_Data() {
      if (this.paymentTableValues.length) {
        let tableData = [];
        tableData = this.paymentTableValues.map((item) => ({
          "Payment Number": item.paymentNumber,
          "Base Amount": item.baseAmount,
          "Claim Amount": item.claimAmount,
          "Cumulative Total": item.cumulativeTotal,
          Approval: item.approval,
          "Approved Date": item.approvedDate,
          "Payment Date": item.paymentDate,
          "Benefit Type": item.benefitType,
        }));
        exportData(tableData);
      }
    },

    paymentApprove() {
      if (this.numberOfApprovePayment) {
        this.paymentTableValues.forEach((row, index) => {
          if (index < this.numberOfApprovePayment) {
            row.approval = "Approved";
            row.approvedDate = this.$day.newDate();
            let dt = new Date();
            dt.setDate(dt.getDate() + 1);
            let month = dt.getMonth() + 1;
            if (dt.getMonth() + 1 < 10) {
              month = "0" + dt.getMonth();
            }
            let day = dt.getDate();
            if (dt.getDate() < 10) {
              day = "0" + dt.getDate();
            }
            row.paymentDate = `${dt.getFullYear()}-${month}-${day}`;
          } else {
            row.approval = "";
            row.approvedDate = "";
            row.paymentDate = "";
          }
        });
      } else {
        this.$alert.warning("Specify the number of payments to approve");
      }
    },

    clearPaymentApprove() {
      this.numberOfApprovePayment = 0;
      this.paymentTableValues.forEach((row) => {
        row.approval = "";
        row.approvedDate = "";
      });
    },

    updateApprovalDate(rowIndex) {
      if (this.paymentTableValues[rowIndex].approval === "Approved") {
        this.paymentTableValues[rowIndex].approvedDate = this.$day.newDate();
        let dt = new Date();
        dt.setDate(dt.getDate() + 1);
        let month = dt.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let day = dt.getDate();
        if (day < 10) {
          day = "0" + day;
        }
        this.paymentTableValues[
          rowIndex
        ].paymentDate = `${dt.getFullYear()}-${month}-${day}`;
      } else {
        this.paymentTableValues[rowIndex].approvedDate = "";
        this.paymentTableValues[rowIndex].paymentDate = "";
      }
    },

    async paymentCalculation() {
      this.numberOfApprovePayment = 0;
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.paymentTableValues = [];
      let total = 0;
      let benefit = 0;
      if (this.benefitType) {
        switch (this.benefitType) {
          case "Monthly":
            benefit = 7;
            break;
          case "BiWeekly":
          case "SemiMonthly":
            benefit = 13;
            break;
          case "Weekly":
            benefit = 25;
            break;
        }
      }
      for (let i = 1; i <= benefit; i++) {
        // let baseAmount = this.totalPayment * 1.2;
        // let claimAmount = baseAmount + baseAmount * 0.2;
        let baseAmount = this.totalPayment;
        let claimAmount = baseAmount + baseAmount * 0.2;
        total += claimAmount;
        if (total + this.lumpsumAmount > 4000) {
          return;
        }
        this.paymentTableValues.push({
          paymentNumber: i,
          baseAmount: baseAmount.toFixed(2),
          claimAmount: claimAmount.toFixed(2),
          cumulativeTotal: total.toFixed(2),
          approval: "",
          approvedDate: "",
          paymentDate: "",
          benefitType: this.benefitType,
        });
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.selectedProcess.processId) {
          // this.$store.commit("showLoadingBarPage");
          const { error } = await workflow.insertProcessComment(
            this.selectedWorkflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
            }
          );

          // this.$store.commit("hideLoadingBarPage");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.actionRemarks = false;
          this.actionPassword = false;
          this.requestAction(this.actionText, false, false);
          this.actionText = "";
        }
      }
    },

    async validatePassword() {
      const areAllFieldsValid = await this.$refs.passwordForm.validate();

      if (!areAllFieldsValid) {
        return;
      }
      this.isLoading = true;
      const { error } = await auth.validatePassword({
        password: this.actionUserPassword,
      });
      this.isLoading = false;
      if (error) {
        console.log(error);
        this.$alert.warning(error);
        return;
      }
      this.actionUserPassword = "";
      this.actionPasswordConfirm = false;
      if (this.actionRemarks) {
        this.advancedCommentsSheet = true;
      } else {
        this.actionRemarks = false;
        this.actionPassword = false;
        this.requestAction(this.actionText, false, false);
        this.actionText = "";
      }
    },

    async updatePaymentTable(approveAction) {
      this.paymentTableValues.forEach((row) => {
        row.baseAmount = Number(row.baseAmount).toFixed(2);
      });
      let input = {
        workflowId: this.selectedWorkflow.id,
        processId: this.selectedProcess.processId,
        paymentTableList: this.paymentTableValues,
      };
      if (this.isPaymentFound) {
        const { error } = await custom.updatePaymentTable(
          this.selectedProcess.processId,
          input
        );

        if (error) {
          this.$alert.error(error);
          return;
        }
      } else {
        const { error } = await custom.savePaymentTable(input);

        if (error) {
          this.$alert.error(error);
          return;
        }
      }

      if (approveAction) {
        this.requestAction(approveAction, false, false);
      }
    },

    async getHideColumns() {
      this.hideColumns = [];
      const { error, payload } = await menu.getHideColumns(
        this.selectedWorkflow.id
      );

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.hideColumns = payload;
      }
    },

    async getPortal() {
      const { error, payload } = await portal.getPortalList({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "workflowId",
                condition: "IS_EQUALS_TO",
                value: this.selectedWorkflow.id,
              },
            ],
            groupCondition: "",
          },
        ],
        hasSecurity: false,
        itemsPerPage: 50,
        currentPage: 1,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }

      const { data, meta } = payload;
      if (meta.totalItems) {
        if (data.length) {
          if (data[0].value.length) {
            this.portalAction = true;
          }
        }
      }
    },

    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    async getFilterList() {
      this.loadingBarContent = true;
      let userId = this.$store.state.session.id.toString();
      const { error, payload } = await dashboard.getDashboardData({
        sortBy: this.sortBy,
        groupBy: "",
        filterBy: [
          {
            groupCondition: "",
            filters: [
              {
                criteria: "filterFrom",
                condition: "IS_EQUALS_TO",
                value: "workflowInbox",
              },
              {
                criteria: "createdBy",
                condition: "IS_EQUALS_TO",
                value: userId,
              },
            ],
          },
        ],
        currentPage: this.currentPage,
        itemsPerPage: this.itemsPerPage,
      });
      this.loadingBarContent = false;
      this.$store.commit("hideLoadingBarPage");
      if (error) {
        this.$alert.error(error);
        return;
      }
      let data = payload.data[0].value;
      this.listedData = data;
    },
    deleteItem() {
      this.listedData.map((item) => {
        const parse = JSON.parse(item.filterJson);
        const types = parse[0].node.split("-");
        let node = types[0];
        if (
          parse[0].uId === this.selectedWorkflow.id &&
          node === this.selectedNode
        ) {
          this.isDeleteConfirmationVisible = true;
          this.deleteData = {
            id: item.id,
            name: item.name,
            description: item.description,
            filterJson: item.filterJson,
            filterFrom: item.filterFrom,
            createdAt: item.createdAt,
            modifiedAt: item.modifiedAt,
            createdBy: item.createdBy,
            modifiedBy: item.modifiedBy,
            isDeleted: true,
          };
        }
      });
    },

    async save() {
      this.success = false;
      let filter = JSON.stringify(this.filterBy);
      let filtering = [
        {
          uId: this.selectedWorkflow.id,
          refreshCount: this.refreshCount,
          node: this.nodeType,
          filters: filter,
        },
      ];
      let filterss = JSON.stringify(filtering);
      let userId = [this.$store.state.session.id];
      let now = new Date();
      let timestamp = now.toISOString();
      let name = "";
      let description = "";
      if (this.nodeType === "inbox") {
        name = "My Inbox" + "  " + this.selectedNode + "  " + timestamp;
        description = "My Inbox";
      } else {
        name =
          this.selectedWorkflow.name +
          "  " +
          this.selectedNode +
          "  " +
          timestamp;
        description = this.selectedWorkflow.description;
      }

      let input = {
        name: name,
        description: description,
        filterJson: filterss,
        filterFrom: "workflowInbox",
        pinDashboard: true,
        assignTo: JSON.stringify(userId),
      };
      const { error } = await dashboard.saveDashboard(input);
      if (error) {
        this.$alert.warning("The Name Already Registered ");
        return;
      }
      //this.success = true;
      this.dashboard = true;
      this.getFilterList();
      this.$alert.success("Pinned SuccessFully");
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  padding: 16px 0px;
  height: calc(100vh - 208px);
}

#workflowInbox {
  .card {
    background-color: var(--component-bg-color);
    border-radius: 6px;
    height: calc(100vh - 215px);
    border: 1px solid var(--component-bg-color);
  }
}

.calculateBtn {
  margin-top: 40px;
}

#modal #dataValidation,
#modal #paymentTable {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
        &.actions {
          width: 48px;
        }
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }
  }
}

#modal #processHistory {
  #history {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }

    .comments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    .attachments {
      color: var(--primary);
      text-align: left;
      cursor: pointer;
    }

    #comments1 {
      width: 100%;
      border-collapse: collapse;
      white-space: nowrap;
      // margin-bottom: 15px;
      background: #fcfafa;
      table-layout: fixed;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;

          &.small {
            width: 70px;
          }

          &.medium {
            width: 90px;
          }

          &.large {
            width: 210px;
          }
        }
      }

      th {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;

        &.commentsText {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &.commentsText:hover {
          overflow: visible;
          white-space: normal;
          word-break: break-all;
        }
      }
    }

    #attachments {
      width: 98%;
      border-collapse: collapse;
      white-space: nowrap;
      margin-bottom: 5px;
      background: #fcfafa;

      tr {
        height: 36px;

        th {
          color: var(--primary);
          text-align: left;
        }
      }

      th,
      td {
        border: 1px solid var(--divider-color);
        padding: 8px;
        font-weight: 500;
      }

      .comments {
        color: var(--secondary);
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
</style>
